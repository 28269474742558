import React from 'react';
import HomePage from './pages/HomePage';
import LandingPage from './pages/LandingPage';
const App = () => (
  <div className="App">
    <div id="fb-root"></div>
    <script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v18.0" nonce="82KDSiZ6"></script>
    <LandingPage />
  </div>
);

export default App;
