export const ARTICLES_CONTENT = [
    {
        title : 'التهاب الحلق المتكرر',
        content : 'حلقي بيوجعني باستمرار، دائماً في حرقان بالحلق، أشعر بشرخ في حلقي، مع أنَّه لا يوجد ارتفاع في درجة الحرارة. يوجد كثير من الأسباب الأخرى، أهمها ارتجاع في المرء، والتنفس المستمر من الفم. قد يكون هناك انسداد بالأنف أو عادات التدخين.',
        img : 'article_1.jpg'
    },
    {
        title : 'أعراض إلتهاب الجيوب الأنفية',
        content: 'انسداد متكرر بالأنف ​وجود افرازات من الأنف بالصوره الأتيه ​احساس دائم بمرور المخاط من الأنف خلف سقف الحلق الى الفم إحساس متكرر بوجود ألم او ضغط او عدم ارتياح بالوجه حول العين',
        img:'article_2.jpg'
    }
];