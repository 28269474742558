import React, { useState } from 'react';
import { Paper, Tabs, Tab, Typography, Grid } from '@mui/material';
import PlaceIcon from '@mui/icons-material/Place';
import PhoneIcon from '@mui/icons-material/Phone';
import Button from '@mui/material/Button';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';


const ClinicTabs = ({ clinics }) => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Paper elevation={3} style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Tabs value={tabValue} onChange={handleTabChange} centered>
        {clinics.map((clinic, index) => (
          <Tab key={index} label={clinic.name} />
        ))}
      </Tabs>

      {clinics.map((clinic, index) => (
        <div key={index} hidden={tabValue !== index} style={{ padding: '10px', flex: 1, overflow: 'hidden' }}>
          {tabValue === index && (
            <Paper elevation={3} style={{ padding: '10px', flex: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
              <Typography variant="body1" paragraph>
                <PlaceIcon /> {clinic.location}
              </Typography>
              <Typography variant="body1" paragraph>
                <PhoneIcon /> {clinic.telephone}
              </Typography>
              <iframe
                src={clinic.map_link}
                width="100%"
                height="200px"
                allowFullScreen={true}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                style={{ border: 'none', flex: 1 }}
              ></iframe>
              <center>

                <Button
                  variant="contained"
                  color="success"
                  style={{ margin: '10px' }}
                  href={`https://wa.me/${clinic.telephone}`}
                  startIcon={<WhatsAppIcon />}
                >&nbsp;احجز الان&nbsp;</Button>
                <Button
                  variant="contained"
                  color="success"
                  style={{ margin: '10px' }}
                  href={`tel:${clinic.telephone}`}
                  startIcon={<PhoneIcon />}
                >&nbsp;اتصل الان&nbsp;</Button>
              </center>
            </Paper>
          )}
        </div>
      ))}
    </Paper>

  );
};

export default ClinicTabs;
