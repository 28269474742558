import React from 'react';
import {
    AppBar,
    Container,
    CssBaseline,
    Drawer,
    Grid,
    IconButton,
    Paper,
    Toolbar,
    Typography,
    useTheme,
    useMediaQuery,
    Button,
} from '@mui/material';
import { styled } from '@mui/system';
import ClinicTabs from './ClinicTabs';
import { ARABIC_VALUES } from '../arabicStrings';
import FacebookIcon from '@mui/icons-material/Facebook';
import { ARTICLES_CONTENT } from '../ArticlesContent'
import {FACEBOOK_PAGE_URL} from '../appConstants'
const StyledDrawer = styled(Drawer)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'none',  // Remove side menu for large windows
    },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2)
}));

const LandingPage = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Example usage
    const clinics = [
        { name: 'الدقي', location: 'الدقي - 106 شارع التحـريــر - الدور الخامس', telephone: '201011228968', map_link: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3453.9375248444953!2d31.20903808712504!3d30.03865016123409!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x145846d4b9700001%3A0xe4a414759ddf8302!2s106%20El%20Tahrir%2C%20Ad%20Doqi%20A%2C%20Dokki%2C%20Giza%20Governorate%203753002%2C%20Egypt!5e0!3m2!1sen!2snl!4v1704074715867!5m2!1sen!2snl' },
        // Add more clinics as needed
    ];

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', width: '100%', direction: 'rtl' }}>
            <CssBaseline />
            <AppBar position="fixed">
                <Toolbar>
                    <IconButton color="inherit" href="https://www.facebook.com/people/%D8%AF%D9%83%D8%AA%D9%88%D8%B1-%D8%A3%D8%AD%D9%85%D8%AF-%D8%B9%D8%A7%D8%AF%D9%84-%D8%B9%D8%A8%D8%AF%D8%A7%D9%84%D9%84%D9%87-%D8%A5%D8%B3%D8%AA%D8%B4%D8%A7%D8%B1%D9%8A-%D8%AC%D8%B1%D8%A7%D8%AD%D8%A7%D8%AA-%D8%A7%D9%84%D8%A3%D9%86%D9%81-%D9%88%D8%A7%D9%84%D8%A3%D8%B0%D9%86-%D9%88%D8%A7%D9%84%D8%AD%D9%86%D8%AC%D8%B1%D8%A9/100066763123001/" target="_blank" rel="noopener noreferrer">
                        <FacebookIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        {ARABIC_VALUES.clinic_name}
                    </Typography>
                </Toolbar>
            </AppBar>
            <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                <main style={{ flexGrow: 1, padding: theme.spacing(3), width: '100%' }}>
                    <Toolbar />
                    <Container maxWidth="xl"> {/* Set maxWidth to 'xl' for full-width */}
                        {/* Tabs Section for Clinic Info */}
                        <Grid container spacing={2}>
                            {/* Banner Section */}
                            <Grid item xs={12} md={6}>
                                <StyledPaper elevation={3} style={{ height: '100%' }}>
                                    {/* Replace the URLs with actual image URLs */}
                                    <img src="banner.png" alt="Banner" style={{ width: '100%', height: '100%' }} />
                                </StyledPaper>
                            </Grid>

                            {/* Clinic Tabs Section */}
                            <Grid item xs={12} md={6}>
                                <ClinicTabs clinics={clinics} />
                            </Grid>

                        </Grid>

                        {/* Articles Section */}
                        <Grid container spacing={3} marginTop={1} alignItems="stretch">
                            {ARTICLES_CONTENT.map((article, index) => (
                                <Grid item key={index} xs={12} md={4}>
                                    {/* Adjust minHeight value based on your design */}
                                    <StyledPaper elevation={3}>
                                        {/* Replace the content with actual article data */}
                                        <Typography variant="h6" gutterBottom>
                                            {article.title}
                                        </Typography>
                                        <img src={article.img} alt={`Article`} style={{ width: '100%', height: '200px', objectFit: 'cover' }} />
                                        <Typography variant="body1" paragraph style={{height: '150px'}}>
                                            {article.content}
                                        </Typography>
                                        <center>
                                            <Button variant="contained" color="success" href={FACEBOOK_PAGE_URL}>
                                                المزيد
                                            </Button>
                                        </center>
                                    </StyledPaper>
                                </Grid>
                            ))}
                        </Grid>
                    </Container>
                </main>
            </div>
        </div>
    );
};

export default LandingPage;
